<template>
    <v-container class="w-container-edit-scadenza v-overflow cont_tipologie_gruppo_mail_doxweb"
        style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#8ec8a3">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Company </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="gruppo_mail_tipologie.companyid" interface="action-sheet" disabled
                                readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="nome" v-model="gruppo_mail_tipologie.nome" label="Name" required
                        :rules="nameRules"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="descrizione" v-model="gruppo_mail_tipologie.descrizione"
                        label="Description"></v-text-field>

                </v-col>

            </v-row>




        </v-form>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Company
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_doxweb.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-doxweb.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>



    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apiscadenze from "../utils/scadenze/apiscadenze";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        if (this.$route.params.id == 0) {

            this.titolo = "Add Tipology";

            this.syncAziende();

        }

        if (this.$route.params.id > 0) {
            this.titolo = "Edit Tipology";

            this.initEdit();

        }

        var pointerVue = this;

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":


                    this.validate();

                    break;

                case "btn_back":


                    router.push({
                        path: "/tipologieGruppiMail"
                    });

                    break;



                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#d_frequenza', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFrequenza');

            });


            $('body').on('click', '#d_tempo', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupTempo');

            });




            $('body').on('ionChange', '.tipo_progetto_id', function (event) {
                console.log(event);

                pointerVue.scadenza.tipo_progetto_id = this.value;

                pointerVue.setPratiche(pointerVue.scadenza.companyid, pointerVue.scadenza.tipo_progetto_id);

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.praticaid', function (event) {
                console.log(event);

                pointerVue.scadenza.praticaid = this.value;

                pointerVue.sezAltro = true;

                pointerVue.setupButtons();

            });


            $('body').on('ionChange', '.delivery_hour_time', function (event) {
                console.log(event);

                pointerVue.scadenza.delivery_hour_time = this.value;

                pointerVue.setupButtons();

            });





            $('body').on('ionChange', '.id_tipo_controllo', function (event) {
                console.log(event);

                pointerVue.scadenza.id_tipo_controllo = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.idresources_group_name', function (event) {
                console.log(event);

                pointerVue.scadenza.idresources_group_name = this.value;

            });

            $('body').on('ionChange', '.status', function (event) {
                console.log(event);

                pointerVue.scadenza.status = this.value;

            });



            $('body').on('blur', '#nome', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });


        pointerVue.setupButtons();

    },


    data: () => ({

        gruppo_mail_tipologie: {
            companyid: 0,
            nome: "",
            descrizione: "",
            prodotto: "",
        },

        sezPratica: false,
        sezAltro: false,

        removeValue: "S",

        valorePosizione: null,

        valoreFrequenza: null,

        valoreTempo: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        scadenza: {
            nome: "",
            praticaid: "",
            companyid: "",
            send_as_mail: "N",
            send_as_sms: "N",
            send_as_wapp: "N",
            enable_history: "N",
            days_before_sent: "",
            enabled: 0,
            idresources_group_name: "",
        },

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        tipo_progetto: [],

        pratiche: [],

        praticheTemp: [],

        gruppi: [],

        tipo_controllo: [],

        stati: [{ id: "N", name: "No" }, { id: "Y", name: "Si" }, { id: "1", name: "NA" }, { id: "2", name: "WIP" }],

        currAzienda: "",
        titolo: "Aggiungi Scadenza",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Campo richiesto",
        ],

        dataRules: [
            (v) => !!v || "Campo richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },


    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.gruppo_mail_tipologie.companyid = azienda.companyid;


        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.goGetCompanyNew(
                v_token,
                "emt"
            ).then((res) => {


                that.$root.$children[0].showProgress = false;
                console.log("res from goGetCompanyNew", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        initEdit: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.editTipologiaGruppoMail(
                v_token,
                "emt",
                that.$route.params.id
            ).then((res) => {


                that.$root.$children[0].showProgress = false;
                console.log("res from editTipologiaGruppoMail", res);

                try {

                    this.aziende = res.data.company;

                    this.gruppo_mail_tipologie = res.data.tipologia;

                    $("#itemCompany").addClass("item-has-value");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: this.getUrlDomain("public/_lib/img/icon_save_doxweb.png"),
                    title: "Salva",
                    width: 30
                });


                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: this.getUrlDomain("public/_lib/img/back-doxweb2.png"),
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },


        validate() {

            var that = this;

            var errore = "";

            if (that.gruppo_mail_tipologie.companyid == 0) {
                errore = "Company required";
            }

            if (errore == "") {
                if (that.gruppo_mail_tipologie.nome == "") {
                    errore = "Name required";
                }
            }

            if (errore == "") {
                if (that.gruppo_mail_tipologie.descrizione == "") {
                    errore = "Description required";
                }
            }

            if (errore == "") {

                console.log("ok");

                this.saveData();

            }
            else {
                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },

        validateOld() {

            $("#itemCompany").removeClass("clDDLError");
            $("#itemCompany > ion-label").removeClass("clDDLLabelError");
            $("#itemCompany").addClass("clDDL");
            $("#itemCompany > ion-label").addClass("clDDLLabel");
            $("#msgErrCompany").html("&nbsp;");

            $("#itemPratica").removeClass("clDDLError");
            $("#itemPratica > ion-label").removeClass("clDDLLabelError");
            $("#itemPratica").addClass("clDDL");
            $("#itemPratica > ion-label").addClass("clDDLLabel");
            $("#msgErrPratica").html("&nbsp;");

            $("#itemGruppo").removeClass("clDDLError");
            $("#itemGruppo > ion-label").removeClass("clDDLLabelError");
            $("#itemGruppo").addClass("clDDL");
            $("#itemGruppo > ion-label").addClass("clDDLLabel");
            $("#msgErrGruppo").html("&nbsp;");

            $(".giorni_rim .v-text-field__slot").css("border-bottom", "1px solid #a8a8a8");
            $("#msgErrGiorni").html("&nbsp;");

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Verifica i dati",
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {


                var errore = 0;


                if (this.scadenza.companyid == "") {

                    $("#itemCompany").removeClass("clDDL");
                    $("#itemCompany > ion-label").removeClass("clDDLLabel");
                    $("#itemCompany").addClass("clDDLError");
                    $("#itemCompany > ion-label").addClass("clDDLLabelError");
                    $("#msgErrCompany").html("Campo richiesto");

                    errore = 1;

                }



                if (this.scadenza.praticaid == "") {

                    $("#itemPratica").removeClass("clDDL");
                    $("#itemPratica > ion-label").removeClass("clDDLLabel");
                    $("#itemPratica").addClass("clDDLError");
                    $("#itemPratica > ion-label").addClass("clDDLLabelError");
                    $("#msgErrPratica").html("Campo richiesto");

                    errore = 1;

                }





                if (this.scadenza.idresources_group_name == "") {

                    $("#itemGruppo").removeClass("clDDL");
                    $("#itemGruppo > ion-label").removeClass("clDDLLabel");
                    $("#itemGruppo").addClass("clDDLError");
                    $("#itemGruppo > ion-label").addClass("clDDLLabelError");
                    $("#msgErrGruppo").html("Campo richiesto");

                    errore = 1;

                }





                if (errore == 0) {

                    if (this.scadenza.days_before_sent == "") {


                        $(".giorni_rim .v-text-field__slot").css("border-bottom", "1px solid red");
                        $("#msgErrGiorni").html("Campo richiesto");

                        errore = 1;

                    }


                }


                if (errore == 0) {

                    if (this.scadenza.days_before_sent > 60) {


                        $(".giorni_rim .v-text-field__slot").css("border-bottom", "1px solid red");
                        $("#msgErrGiorni").html("Valore massimo 60");

                        errore = 1;

                    }


                }



                if (errore == 0) {

                    console.log("ok");

                    this.saveData();

                }
                else {
                    this.$swal({
                        icon: "error",
                        text: "Verifica i dati",
                        showConfirmButton: false,
                        timer: 2000
                    });



                }




            }
        },



        saveData: async function () {

            var that = this;

            that.gruppo_mail_tipologie.prodotto = "doxweb";

            var response = await apiscadenze.saveTipologiaGruppoMail(
                that.$route.params.id,
                that.gruppo_mail_tipologie,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveTipologiaGruppoMail", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {


                    router.push({
                        path: "/tipologieGruppiMail/"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );




        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.clNumLabel {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
}

.clNumLabelError {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
    color: red !important;
}


.clMsgError {
    color: red;
    margin-top: -11px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clMsgError2 {
    color: red;
    margin-top: -11px;
    font-size: 12px;
    font-weight: 600;
}


.clNumMsgError {
    color: red;
    margin-top: -26px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clDDL {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important
}

.clDDLLabel {
    font-size: 11px !important;
    color: black !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}


.clDDLError {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important;
    border-bottom: 1px solid red !important;
}

.clDDLLabelError {
    font-size: 11px !important;
    color: red !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}




.w-container-edit-scadenza {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-scadenza {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.cont_tipologie_gruppo_mail_doxweb .v-label {
    font-size: 11px !important;
}


.cont_tipologie_gruppo_mail_doxweb .v-input {
    font-size: 11px !important;
    padding: 10px !important;
}

.cont_tipologie_gruppo_mail_doxweb ion-select {
    font-size: 11px !important;
}

.cont_tipologie_gruppo_mail_doxweb .cl-checkbox {
    margin-left: 0px !important;
}


.cont_tipologie_gruppo_mail_doxweb .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.cont_tipologie_gruppo_mail_doxweb .v-input.cl-checkbox.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
    margin-top: 0px !important;
}

.cont_tipologie_gruppo_mail_doxweb .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
}


.w-container-edit-scadenza .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}
</style>
